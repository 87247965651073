<template>
  <div class="categories">
    <h5 class="mb-4">{{ $t("ai-generated") }}</h5>

    <p>
      Some of our sales partners, including Amazon, collect data on the use of
      AI tools to create book content. Currently, the use of AI does not affect
      the likelihood that your book will be accepted or rejected by a sales
      channel.
    </p>
    <p>
      <b>If you created the content yourself,</b> and used AI-based tools to
      edit, refine, error-check, or otherwise improve that content (whether text
      or images), you can answer ‘No’ Below.
    </p>
    <p>
      <b>If you used an AI-based tool to create the actual content</b> (whether
      text, images, or translations, even if you applied substantial edits
      afterwards, please answer ‘Yes’ and provide the additional information
      requested.
    </p>
    <FormGenerator
      :elements="elements"
      :handleUpdate="handleUpdate"
      :data="book"
      inline
      v-bind="$attrs"
    />
  </div>
</template>

<script>
import FormGenerator from "@/components/form-generator";

export default {
  name: "aigenerated",
  components: {
    FormGenerator
  },
  props: {
    book: {
      type: Object,
      required: true
    },
    handleUpdate: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      searchTerm: ""
    };
  },
  computed: {
    elements() {
      return [
        {
          key: "ai_tools",
          label: "did_you_use_ai_tools",
          type: "switch"
        },
        {
          key: "ai_text",
          label: "text",
          type: "select",
          attrs: {
            options: [
              {
                text: "None",
                value: null
              },
              {
                text: "Some sections, with minimal or no editing",
                value: 1
              },
              {
                text: "Some sections, with extensive editing",
                value: 2
              },
              {
                text: "Entire work, with minimal or no editing",
                value: 3
              },
              {
                text: "Entire work, with extensive editing",
                value: 4
              }
            ]
          },
          showIf: ["ai_tools"]
        },
        {
          key: "text_ai_tools",
          label: "which_tool_used_for_text",
          type: "tags",
          placeholder: "e.g. ChatGPT",
          attrs: {
            options: [this.book.text_ai_tools],
            limit: 3
          },
          showIf: ["ai_text"]
        },
        {
          key: "ai_image",
          label: "image",
          type: "select",
          attrs: {
            options: [
              {
                text: "None",
                value: null
              },
              {
                text:
                  "One or a few AI-generated images, with minimal or no editing",
                value: 1
              },
              {
                text:
                  "One or a few AI-generated images, with extensive editing",
                value: 2
              },
              {
                text: "Many AI-generated images, with minimal or no editing",
                value: 3
              },
              {
                text: "Many AI-generated images, with extensive editing",
                value: 4
              }
            ]
          },
          showIf: ["ai_tools"]
        },
        {
          key: "image_ai_tools",
          label: "which_tool_used_for_image",
          type: "tags",
          placeholder: "e.g. DALL-E",
          attrs: {
            options: [this.book.image_ai_tools],
            limit: 3
          },
          showIf: ["ai_image"]
        },
        {
          key: "ai_translation",
          label: "translation",
          type: "select",
          attrs: {
            options: [
              {
                text: "None",
                value: null
              },
              {
                text: "Some sections, with minimal or no editing",
                value: 1
              },
              {
                text: "Some sections, with extensive editing",
                value: 2
              },
              {
                text: "Entire work, with minimal or no editing",
                value: 3
              },
              {
                text: "Entire work, with extensive editing",
                value: 4
              }
            ]
          },
          showIf: ["ai_tools"]
        },
        {
          key: "translation_ai_tools",
          label: "which_tool_used_for_translation",
          type: "tags",
          placeholder: "e.g. Google Translate",
          attrs: {
            options: [this.book.translation_ai_tools],
            limit: 3
          },
          showIf: ["ai_translation"]
        }
      ];
    }
  },
  methods: {}
};
</script>

<style></style>
